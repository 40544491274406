
  /**
   * (c) 2021, Fujitsu General. All rights reserved
   * Author: Guillermo Gonzalez
   * Date: 2, August 2021
   *
   * Summary: SettingsDashboardContainer component
   */
  import { Component, Vue } from 'vue-property-decorator';
  import SettingsDashboard from '../../dashboards/settings-dashboard/SettingsDashboard.vue';

  @Component({
    components: {
      SettingsDashboard
    }
  })
  export default class SettingsDashboardContainer extends Vue {}
