
  /**
   * (c) 2021, Fujitsu General. All rights reserved
   * Author: Guillermo Gonzalez
   * Date: 2, August 2021
   *
   * Summary: Settings Dashboard
   */
  import { Component, Vue } from 'vue-property-decorator';
  import NavigationPills from '../../common/navigation-pills/NavigationPills.vue';

  @Component({
    components: {
      'nav-pills': NavigationPills
    }
  })
  export default class SettingsDashboard extends Vue {
    private get options() {
      return [
        {
          name: 'label.pills.my-profile',
          route: { name: 'MyProfile', path: '/settings/my-profile' },
          icon: require('*/images/icons/my-profile.svg'),
          isHidden: false
        },
        {
          name: 'label.pills.my-company',
          route: { name: 'MyCompany', path: '/settings/my-company' },
          icon: require('*/images/icons/my-company.svg'),
          isHidden: !this.$can('display', 'MyCompanyPill')
        },
        {
          name: 'label.pills.users',
          route: { name: 'Users', path: '/settings/users' },
          icon: require('*/images/icons/users.svg'),
          isHidden: !this.$can('display', 'UsersPill')
        }
      ];
    }
  }
